import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

export default ({ handleChangeTextColor }) => {
  const [displayTextColorPicker, setDisplayTextColorPicker] = useState(false);
  const [textColor, setColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });

  const handleClick = () => {
    setDisplayTextColorPicker(!displayTextColorPicker);
  };

  const handleClose = () => {
    setDisplayTextColorPicker(false);
  };

  const handleChange = (textColor) => {
    setColor(textColor.rgb);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        background: `rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, ${
          textColor.a
        })`,
      },
      swatch: {
        background: "none",
        borderRadius: "0px",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        right: 80,
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayTextColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={textColor}
            onChange={handleChange}
            onChangeComplete={handleChangeTextColor}
          />
        </div>
      ) : null}
    </div>
  );
};

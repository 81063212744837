import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import data from "../api/v2/networks.json";
import "../styles/styles.scss";
import SEO from "../components/SEO";
import { fabric } from "fabric";
import ColorPicker from "../components/ColorPicker";
import ShapeColorPicker from "../components/ShapeColorPicker";
import TextColorPicker from "../components/TextColorPicker";
import { initAligningGuidelines } from "../components/AlignmentGuidelines";
import { initCenteringGuidelines } from "../components/CenteringGuidelines";
import FontFaceObserver from "fontfaceobserver";
import SearchPhotos from "../components/SearchPhotos";

export default () => {
  const [hasFont, setHasFont] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState("Facebook News Feed");
  const [selectedSize, setSelectedSize] = useState("1200x1200");
  const [selectedFont, setSelectedFont] = useState("Times New Roman");
  const [selectedColor, setSelectedColor] = useState("#FFFFFF");
  const [selectedTextColor, setSelectedTextColor] = useState("#000000");
  const [selectedShapeColor, setSelectedShapeColor] = useState("#000000");
  const [showMenu, setShowMenu] = useState(false);
  const isMobile =
    typeof window !== `undefined` ? window.innerWidth <= 620 : null;

  const fontA = new FontFaceObserver("Roboto");
  const fontB = new FontFaceObserver("Open Sans");
  const fontC = new FontFaceObserver("Playfair Display");
  const fontD = new FontFaceObserver("Rubik");
  const fontE = new FontFaceObserver("Merriweather");
  const fontF = new FontFaceObserver("Bebas Neue");

  useEffect(() => {
    if (!hasFont)
      Promise.all([
        fontA.load(),
        fontB.load(),
        fontC.load(),
        fontD.load(),
        fontE.load(),
        fontF.load(),
      ]).then(() => {
        setHasFont(true);
      });
  }, [hasFont]);

  const fabricCanvas = useRef(new fabric.Canvas());
  const canvasEl = useRef();
  const canvasWidth = 1200;
  const canvasHeight = 1200;
  useEffect(() => {
    fabricCanvas.current.initialize(canvasEl.current, {
      width: isMobile ? canvasWidth / 4 : canvasWidth / 2,
      height: isMobile ? canvasHeight / 4 : canvasHeight / 2,
      backgroundColor: "white",
      preserveObjectStacking: true,
      objectCaching: false,
    });
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "#ff6c14";
    fabric.Object.prototype.cornerStyle = "rect";
    fabric.Object.prototype.cornerSize = "8";
    fabric.Object.prototype.borderColor = "#ff6c14";
    fabric.Textbox.prototype._wordJoiners = /[]/;
    initAligningGuidelines(fabricCanvas.current);
    initCenteringGuidelines(fabricCanvas.current);
    return () => {
      fabricCanvas.current.dispose();
    };
  }, [canvasEl.current]);

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      const keycode = e.keyCode;
      const canvas = fabricCanvas.current;

      if (e.target.matches("input")) return;

      if (keycode === 8 || keycode === 46) {
        e.preventDefault();
        const activeObjects = canvas.getActiveObjects();
        if (!activeObjects[0] || activeObjects[0].isEditing) return;
        canvas.remove(...activeObjects);
        canvas.discardActiveObject().renderAll();
      }
      if (keycode === 39) {
        if (canvas.getActiveObject()) {
          canvas.getActiveObject().left += 5;
          canvas.renderAll();
        } else return null;
      }
      if (keycode === 37) {
        if (canvas.getActiveObject()) {
          canvas.getActiveObject().left -= 5;
          canvas.renderAll();
        } else return null;
      }
      if (keycode === 40) {
        if (canvas.getActiveObject()) {
          canvas.getActiveObject().top += 5;
          canvas.renderAll();
        } else return null;
      }
      if (keycode === 38) {
        if (canvas.getActiveObject()) {
          canvas.getActiveObject().top -= 5;
          canvas.renderAll();
        } else return null;
      } else return null;
    });
  }, []);

  useEffect(() => {
    const handleClick = (e) => {
      const canvas = fabricCanvas.current;

      // Check if the clicked element is NOT within the excluded div
      if (
        canvas.wrapperEl &&
        !document.getElementById("edit").contains(e.target) &&
        !canvas.wrapperEl.contains(e.target)
      ) {
        canvas.discardActiveObject().renderAll();
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const changeFontFamily = (e) => {
    const canvas = fabricCanvas.current;
    const font = e.target.value;
    setSelectedFont(font);
    if (
      canvas.getActiveObject() &&
      canvas.getActiveObject().type === "textbox"
    ) {
      canvas.getActiveObject().set("fontFamily", font);
      canvas.requestRenderAll();
    } else {
      return null;
    }
  };

  const addRect = () => {
    const canvas = fabricCanvas.current;
    const rect = new fabric.Rect({
      top: 25,
      left: 50,
      fill: "#000",
      width: 250,
      height: 250,
      opacity: 0.3,
      objectCaching: false,
    });
    canvas.add(rect);
    canvas.setActiveObject(rect);
    canvas.renderAll();
  };

  const addCircle = () => {
    const canvas = fabricCanvas.current;
    const circle = new fabric.Circle({
      top: 25,
      left: 50,
      radius: 50,
      fill: "#000",
      opacity: 0.3,
    });
    canvas.add(circle);
    canvas.setActiveObject(circle);
    canvas.renderAll();
  };

  const addTriangle = () => {
    const canvas = fabricCanvas.current;
    const triangle = new fabric.Triangle({
      top: 25,
      left: 50,
      radius: 50,
      fill: "#000",
      opacity: 0.3,
    });
    canvas.add(triangle);
    canvas.setActiveObject(triangle);
    canvas.renderAll();
  };

  const addText = () => {
    const canvas = fabricCanvas.current;
    const text = new fabric.Textbox("Add your text", {
      top: 25,
      left: 50,
      centeredScaling: true,
      fontFamily: `"${selectedFont}"`,
      fontWeight: 700,
      fill: "#000",
      fontSize: 24,
    });
    text.setControlsVisibility({
      mt: false, // middle top disable
      mb: false, // midle bottom
    });
    canvas.add(text);
    canvas.setActiveObject(text);
    canvas.renderAll();
  };

  const setLeft = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().set("left", 0);
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const setHorizontalCenter = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().centerH();
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const setRight = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      const right =
        canvas.getWidth() -
        canvas.getActiveObject().width * canvas.getActiveObject().scaleX;
      canvas.getActiveObject().set("left", right);
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const setBottom = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      const bottom =
        canvas.getHeight() -
        canvas.getActiveObject().height * canvas.getActiveObject().scaleY;
      canvas.getActiveObject().set("top", bottom);
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const setVerticalCenter = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().centerV();
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const setTop = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().set("top", 0);
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const sendToFront = () => {
    const canvas = fabricCanvas.current;
    var activeObj = canvas.getActiveObject();
    activeObj && canvas.bringToFront(activeObj).renderAll();
  };

  const textAlignLeft = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().textAlign = "left";
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const textAlignCenter = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().textAlign = "center";
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const textAlignRight = () => {
    const canvas = fabricCanvas.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().textAlign = "right";
      canvas.renderAll();
    } else {
      return null;
    }
  };

  const clearCanvas = () => {
    const canvas = fabricCanvas.current;
    canvas.remove(...canvas.getObjects());
    canvas.setBackgroundColor("#FFFFFF");
  };

  const sendToBack = () => {
    const canvas = fabricCanvas.current;
    var activeObj = canvas.getActiveObject();
    activeObj && canvas.sendToBack(activeObj).renderAll();
  };

  const deleteItem = () => {
    const canvas = fabricCanvas.current;
    canvas.getActiveObjects().forEach((obj) => {
      canvas.remove(obj);
    });
    canvas.discardActiveObject().renderAll();
  };

  const handleChangeBgColor = (color, event) => {
    const canvas = fabricCanvas.current;
    setSelectedColor(color.hex);
    canvas.setBackgroundColor(color.hex);
    canvas.renderAll();
  };

  const handleChangeTextColor = (textColor, event) => {
    const canvas = fabricCanvas.current;
    setSelectedTextColor(textColor.hex);
    var selection = canvas.getActiveObject();
    selection
      ? selection.set("fill", textColor.hex) &
        selection.set("opacity", textColor.rgb.a)
      : null;
    canvas.renderAll();
  };

  const handleChangeShapeColor = (shapeColor, event) => {
    const canvas = fabricCanvas.current;
    setSelectedShapeColor(shapeColor.hex);
    var selection = canvas.getActiveObject();
    selection
      ? selection.set("fill", shapeColor.hex) &
        selection.set("opacity", shapeColor.rgb.a)
      : null;
    canvas.renderAll();
  };

  const changeSize = (width, height, name) => {
    setSelectedLayout(name);
    setSelectedSize(width + "x" + height);
    const canvas = fabricCanvas.current;
    setTimeout(() => {
      isMobile
        ? canvas.setDimensions({ width: width / 4, height: height / 4 })
        : canvas.setDimensions({ width: width / 2, height: height / 2 });
      canvas.renderAll();
      initCenteringGuidelines(canvas);
    }, 500);
  };

  const addImage = (e) => {
    const canvas = fabricCanvas.current;
    var file = e.target.files[0];
    var reader = new FileReader();
    var currentWidth = canvas.getWidth();
    reader.onload = function (f) {
      var data = f.target.result;
      fabric.Image.fromURL(data, function (img) {
        var oImg = img.scaleToWidth(currentWidth, false).set({
          top: 25,
          left: 50,
          angle: 0,
          originX: "left",
          originY: "top",
        });
        canvas.add(oImg).renderAll();
        canvas.setActiveObject(oImg);
      });
    };
    reader.readAsDataURL(file);
  };

  const download = () => {
    const canvas = fabricCanvas.current;
    canvas.discardActiveObject().renderAll();
    const link = document.createElement("a");
    link.download = "socialsizes.png";
    link.href = document.getElementById("canvas").toDataURL();
    link.click();
  };

  return (
    <div>
      <SEO
        title="#1 Social Image Maker - FREE"
        description="Quick and simple inferface to create your next social media image for facebook, instagram and twitter."
        pathname="/"
      />
      <div className="fabric-header">
        <Link to="/" className="logoLink">
          <svg
            width="25"
            height="25"
            className="logo"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="18"
              y="18"
              width="18"
              height="18"
              rx="2"
              transform="rotate(-180 18 18)"
              fill="url(#a)"
            />
            <rect x="3" y="3" width="12" height="12" rx="1" fill="#fff" />
            <rect x="7" y="7" width="18" height="18" rx="2" fill="url(#b)" />
            <rect x="10" y="10" width="12" height="12" rx="1" fill="#fff" />
            <defs>
              <linearGradient
                id="a"
                x1="27"
                y1="18"
                x2="27"
                y2="36"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFCF00" />
                <stop offset=".396" stopColor="#FF6C14" />
                <stop offset="1" stopColor="#FF007B" />
              </linearGradient>
              <linearGradient
                id="b"
                x1="16"
                y1="7"
                x2="16"
                y2="25"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFCF00" />
                <stop offset=".396" stopColor="#FF6C14" />
                <stop offset="1" stopColor="#FF007B" />
              </linearGradient>
            </defs>
          </svg>
          <div className="name">Social Image Maker.io</div>
        </Link>
        <div className="selectedLayout">
          <div>{selectedLayout}</div>
          <div>
            <span>{selectedSize}</span>
          </div>
        </div>

        <div className="download">
          <button className="button orange" onClick={() => download(canvas)}>
            Download
          </button>
        </div>
      </div>
      <div className="fabric-outer">
        <div className="layout">
          <div className="layout-mobile">
            <div
              className="mobileMenuToggle"
              onClick={() => setShowMenu(!showMenu)}
            >
              <div className="mobileMenuToggleCircle">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fillRule="nonzero">
                    <path d="M21 11a1 1 0 01.117 1.993L21 13H3a1 1 0 01-.117-1.993L3 11h18zM21 20a1 1 0 01.117 1.993L21 22H3a1 1 0 01-.117-1.993L3 20h18zM21 2a1 1 0 01.117 1.993L21 4H3a1 1 0 01-.117-1.993L3 2h18z" />
                  </g>
                </svg>
              </div>
              <div className="mobileMenuToggleContent">
                <div className="mobileMenuToggleTitle">
                  {selectedLayout}
                  <span>{selectedSize}</span>
                </div>
              </div>
            </div>
          </div>
          {showMenu ? (
            <div className="mobileMenu">
              {data.networks.map((id) => (
                <ul key={id.id}>
                  {id.dimensions.map((size) => (
                    <li
                      key={size.item.id}
                      className={
                        selectedLayout === `${id.name} ${size.item.name}`
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        changeSize(
                          size.item.options[0].option.width,
                          size.item.options[0].option.height,
                          `${id.name} ${size.item.name}`
                        ) &
                        setShowMenu(false) &
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      <img
                        src={require(`../images/${id.name.toLowerCase()}.svg`)}
                      />{" "}
                      <span>
                        {id.name} {size.item.name}
                      </span>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          ) : null}
          <div className="desktopMenu">
            <div className="layout-label">Layout</div>
            {data.networks.map((id) => (
              <ul key={id.id}>
                {id.dimensions.map((size) => (
                  <li
                    key={size.item.id}
                    className={
                      selectedLayout === `${id.name} ${size.item.name}`
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      changeSize(
                        size.item.options[0].option.width,
                        size.item.options[0].option.height,
                        `${id.name} ${size.item.name}`
                      )
                    }
                  >
                    <img
                      src={require(`../images/${id.name.toLowerCase()}.svg`)}
                    />{" "}
                    <span>
                      {id.name} {size.item.name}
                    </span>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="canvas-wrapper">
          <div className="button-group">
            <button className="button clear" onClick={clearCanvas}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 5V2L8 6l4 4V7c3.31 0 6 2.69 6 6 0 2.97-2.17 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93 0-4.42-3.58-8-8-8zM6 13c0-1.65.67-3.15 1.76-4.24L6.34 7.34A8.014 8.014 0 004 13c0 4.08 3.05 7.44 7 7.93v-2.02c-2.83-.48-5-2.94-5-5.91z"
                  fill="#334451"
                />
              </svg>
              <span>Clear</span>
            </button>
            <button className="button" onClick={sendToFront}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm2 4v-2H3a2 2 0 002 2zM3 9h2V7H3v2zm12 12h2v-2h-2v2zm4-18H9a2 2 0 00-2 2v10a2 2 0 002 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12H9V5h10v10zm-8 6h2v-2h-2v2zm-4 0h2v-2H7v2z"
                  fill="#334451"
                />
              </svg>
              <span>To front</span>
            </button>
            <button className="button" onClick={sendToBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M9 7H7v2h2V7zm0 4H7v2h2v-2zm0-8a2 2 0 00-2 2h2V3zm4 12h-2v2h2v-2zm6-12v2h2c0-1.1-.9-2-2-2zm-6 0h-2v2h2V3zM9 17v-2H7a2 2 0 002 2zm10-4h2v-2h-2v2zm0-4h2V7h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2zM5 7H3v12a2 2 0 002 2h12v-2H5V7zm10-2h2V3h-2v2zm0 12h2v-2h-2v2z"
                  fill="#334451"
                />
              </svg>
              <span>To back</span>
            </button>
            <button className="button" onClick={deleteItem}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
                  fill="#334451"
                />
              </svg>
              <span>Delete</span>
            </button>
          </div>
          <div className="canvas-outer" id="canvas-container">
            <canvas id="canvas" ref={canvasEl} tabIndex="1000">
              Your browser doesn't support this. You should consider upgrading.
            </canvas>
          </div>
        </div>
        <div className="edit" id="edit">
          <ul className="align-position border">
            <li className="left" onClick={setLeft}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                height="18"
                width="18"
              >
                <path d="M4 44V4h3v40Zm8-24.5v-5h32v5Zm0 14v-5h20v5Z" />
              </svg>
            </li>
            <li className="horizontalcenter" onClick={setHorizontalCenter}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                height="18"
                width="18"
              >
                <path d="M22.5 44V33.5H12v-5h10.5v-9H6v-5h16.5V4h3v10.5H42v5H25.5v9H36v5H25.5V44Z" />
              </svg>
            </li>
            <li className="right" onClick={setRight}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                height="18"
                width="18"
              >
                <path d="M41 44V4h3v40ZM4 19.5v-5h32v5Zm12 14v-5h20v5Z" />
              </svg>
            </li>
            <li className="top" onClick={setTop}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                height="18"
                width="18"
              >
                <path d="M4 7V4h40v3Zm10.5 37V12h5v32Zm14-12V12h5v20Z" />
              </svg>
            </li>
            <li className="verticalcenter" onClick={setVerticalCenter}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                height="18"
                width="18"
              >
                <path d="M14.5 42V25.5H4v-3h10.5V6h5v16.5h9V12h5v10.5H44v3H33.5V36h-5V25.5h-9V42Z" />
              </svg>
            </li>
            <li className="bottom" onClick={setBottom}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                height="18"
                width="18"
              >
                <path d="M4 44v-3h40v3Zm10.5-8V4h5v32Zm14 0V16h5v20Z" />
              </svg>
            </li>
          </ul>
          <div className="bgColor border">
            <div className="bgColor-label">Background</div>
            <div className="bgColor-wrapper">
              <ColorPicker handleChangeBgColor={handleChangeBgColor} />
              <label>{selectedColor}</label>
            </div>
          </div>

          <SearchPhotos fabricCanvas={fabricCanvas.current} />

          <div className="uploadFile border">
            <label htmlFor="file" className="file-upload">
              <div className="file-icon">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                <span>Upload Image</span>
              </div>
            </label>
            <input id="file" type="file" onChange={addImage} />
          </div>

          <div className="shapes border">
            <div className="shapes-label">Shape</div>
            <div className="shapes-wrapper">
              <div
                className="shape-item rectangle"
                onClick={() => addRect(canvas)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="2"
                    fill="#334451"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <div
                className="shape-item circle"
                onClick={() => addCircle(canvas)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    fill="#334451"
                    width="16"
                    height="16"
                    rx="8"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <div
                className="shape-item triangle"
                onClick={() => addTriangle(canvas)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 3.61L1.891 17.67a2.056 2.056 0 00-.005 1.979c.337.614.962.996 1.642 1.004h16.214a1.904 1.904 0 001.642-1.004 2.056 2.056 0 00-.006-1.98L13.273 3.61a1.9 1.9 0 00-1.637-.956 1.9 1.9 0 00-1.637.957z"
                    fill="#334451"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <div className="shape-color">
                <ShapeColorPicker
                  handleChangeShapeColor={handleChangeShapeColor}
                />
                <div className="shape-color-label">{selectedShapeColor}</div>
              </div>
            </div>
          </div>

          <div className="text border">
            <div className="text-label">Text</div>
            <div className="text-wrapper">
              <select id="font-family" onChange={changeFontFamily}>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Roboto">Roboto</option>
                <option value="Open Sans">Open Sans</option>
                <option value="Playfair Display">Playfair Display</option>
                <option value="Rubik">Rubik</option>
                <option value="Merriweather">Merriweather</option>
                <option value="Bebas Neue">Bebas Neue</option>
              </select>
              <div className="text-color">
                <TextColorPicker
                  handleChangeTextColor={handleChangeTextColor}
                />
                <div className="text-color-label">{selectedTextColor}</div>
              </div>
            </div>
            <div className="text-wrapper">
              <div onClick={() => addText(canvas)} className="text-add">
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#334451"
                    fillRule="nonzero"
                    d="M7.413 13V2.524h3.852V.148H.735v2.376h3.852V13z"
                  />
                </svg>
                <span>Add Text</span>
              </div>
              <div className="text-align left" onClick={textAlignLeft}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"
                      fill="#334451"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </div>
              <div className="text-align center" onClick={textAlignCenter}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z"
                      fill="#334451"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </div>
              <div className="text-align right" onClick={textAlignRight}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      d="M3 21h18v-2H3v2zm6-4h12v-2H9v2zm-6-4h18v-2H3v2zm6-4h12V7H9v2zM3 3v2h18V3H3z"
                      fill="#334451"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { createApi } from "unsplash-js";

export default ({ fabricCanvas }) => {
  const unsplash = createApi({
    accessKey: "SUrv5nN9EUDqmfXHM-5KvweoGZ7LHbhp1_aShfCb9NQ",
  });

  const [query, setQuery] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  async function fetchData(currentPage) {
    if (query === "") {
      // If there is no query, fetch random photos instead
      unsplash.photos
        .getRandom({
          count: 12,
          page: currentPage,
          perPage: 12,
        })
        .then((json) => {
          setFetchedData(json.response);
          setCurrentPage(currentPage);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Fetch photos based on the query
      unsplash.search
        .getPhotos({
          query: query,
          page: currentPage,
          perPage: 12,
        })
        .then((json) => {
          setFetchedData(json.response.results);
        })
        .catch((err) => {
          console.log(err.response.results);
        });
    }
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset the page when a new search is made
    fetchData();
  };

  const setUnsplashImage = (pic) => {
    const canvas = fabricCanvas;
    var img = pic.urls.full;
    var currentWidth = canvas.getWidth();
    unsplash.photos.trackDownload({
      downloadLocation: pic.links.download_location,
    });
    fabric.Image.fromURL(
      img,
      function (img) {
        var oImg = img
          .set({
            top: 25,
            left: 50,
            angle: 0,
            originX: "left",
            originY: "top",
          })
          .scaleToWidth(currentWidth, false);

        canvas.add(oImg).renderAll();
        canvas.setActiveObject(oImg);
      },
      { crossOrigin: "Anonymous" }
    );
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="image-label">Image</div>
        <div className="image-search">
          <input
            type="search"
            name="query"
            className="input"
            placeholder={`Try "coffee" or "food"`}
            value={query}
            onInput={() => setQuery("")}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit">Search</button>
        </div>
      </form>

      <div id="images">
        {fetchedData.map((pic) => {
          return (
            <div key={pic.id} className="imageItem">
              <div className="thumbImage">
                <img
                  crossOrigin="anonymous"
                  src={pic.urls.thumb}
                  width="80"
                  height="80"
                  alt={pic.alt_description}
                  onClick={() => setUnsplashImage(pic)}
                />
                <div className="biggerImage">
                  <img
                    crossOrigin="anonymous"
                    src={pic.urls.thumb}
                    width="320"
                    height="320"
                    alt={pic.alt_description}
                    onClick={() => setUnsplashImage(pic)}
                  />
                </div>
              </div>

              <div className="imageDescription">
                <span>Photo by</span>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={
                    "https://unsplash.com/@" +
                    pic.user.username +
                    "?utm_source=SocialSizes.io&utm_medium=referral"
                  }
                >
                  {pic.user.name}
                </a>
                <span>on</span>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://unsplash.com/?utm_source=SocialSizes.io&utm_medium=referral"
                >
                  Unsplash
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pagination">
        <button
          className={currentPage === 1 ? "previous disabled" : "previous"}
          onClick={handlePrevious}
        >
          Previous
        </button>
        <div className="currentPage">Page&nbsp;{currentPage}</div>
        {fetchedData.length > 0 && (
          <button className="next" onClick={handleNext}>
            Next
          </button>
        )}
      </div>
    </>
  );
};
